module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"一棟貸しの宿 もりしま 長野県上田市","short_name":"もりしま","descripthion":"長野県上田市の武石地域で営業しています。一泊一組限定、平屋一棟を貸し切りにして宿泊ができます。食事の提供はできませんが、キッチンや庭のBBQコンロをお使いいただけます。宿泊以外にも会議や教室、勉強会など時間貸しも可能です。お気軽にお問合せください。","start_url":"/","background_color":"#fbfffc","theme_color":"#14302F","display":"standalone","icon":"src/images/mount-icon.png","siteLanguage":"ja","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7ff03fbff79fe176e297a534d11d94e1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
